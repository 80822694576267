import React, { useEffect } from 'react'
import styled from 'styled-components'
import { useStaticQuery, navigate } from 'gatsby'
import { useRecoilState } from 'recoil'
import PressBanner from 'src/components/shared/PressBanner'
import scrollToTop from '../../utils/scrollToTop'
import Billboard from './Billboard'
import CategoryBg from '../../assets/img/categories/dog.png'

import { media } from '../../styles/util'
import { colors } from '../../styles/variables'

import { productDetailState } from '../../actions/products'

import { trackCollectionViewEvent } from '../../utils/event-tracking'
import DropHeader from '../shared/DropHeader'

const Section = styled.div`
  padding: 70px 0 0 0;
  background-color: #fff;
  width: 100%;
  color: ${colors.navy};

  ${media.mobile`
    padding: 55px 0 0 0;
  `}
  ${media.tablet`
    padding: 100px 0 0 0;
  `}
  ${media.desktop`
    padding: 88px 0 0 0;
  `}
`

const Container = styled.div`
  width: 100%;
  justify-content: space-around;

  h1 {
    ${media.desktop`
      padding-bottom: 50px;
    `}
  }

  & h2 {
    color: ${colors.navy};
    ${media.mobile`
      font-size: 42px;
      line-height: 1.35 !important;
      padding: 0 20px;
    `}
    ${media.desktop`
      font-size: 86px;
    `}
  }

  img {
    object-position: top;
  }
`

const Category = ({ title, image, children }) => {
  const [productMap] = useRecoilState(productDetailState)

  const { fetching } = productMap

  useEffect(() => {
    if (!fetching && productMap?.bySlug) {
      const productSlugs = Object.keys(productMap.bySlug)
      trackCollectionViewEvent(productMap, productSlugs)
    }
  }, [fetching])

  return (
    <Section>
      <Container>
        {image && <Billboard image={image} height={580} />}
        <PressBanner
          bg={'lightBlue'}
          color="navy"
          ticking
          homepage
          className="press-banner"
          hideMobile
        />
        {title && (
          <DropHeader title={title} fontSize={133} tabletFontSize={88} mobileFontSize={50} />
        )}
        {children}
        <Billboard
          title={'Made for\nKona'}
          image={CategoryBg}
          ctaText="Take The Quiz"
          ctaLink="/consultation"
          text={
            'Tell us a bit about your dog and get tailor-made supplement recommendations based on factors like age, breed, diet, and history.'
          }
        />
      </Container>
    </Section>
  )
}

export default Category
